export default {
    account: "账号",
    name: "名称",
    phone: "电话",
    mail: "邮箱",
    status: "是否有效",
    createTime: "创建时间",
    operTime: "操作时间",
    operOperatorKey: "操作用户",
    level: "用户等级",
    companyKey: "公司",
    teamKey: "组"
}
