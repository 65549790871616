import {UmEntity, UmQuery} from "../core";



/**
 * 业务用户(Entity)
 * @author syj
 * @date 2021-09-18 15:32
 */
export class UserEntity extends UmEntity {
	//账号
	public account?: string;
	//名称
	public name?: string;
	//电话
	public phone?: string;
	//邮箱
	public mail?: string;
	//是否有效
	public status?: number;
	//0:最高权限 1:公司级权限 2:用户级权限
	public level?: number;
	//公司主键
	public companyKey?: number;
	//用户类型
	public userType?: number;
	//team主键
	public teamKey?: number;

	public nameAndCompany?:string;

	public nameAndTeam?:string;
}


/**
 * 业务用户(Query)
 * @author syj
 * @date 2021-09-18 15:32
 */
export class UserQuery extends UmQuery {
	public  userType?:number;

	public  hasCompany?:boolean;

	public  hasUserOperator?:boolean;

	public  status?:number;

}

/**
 * 用户角色对照(Entity)
 * @author syj
 * @date 2021-09-22 15:00
 */
export class UserRoleEntity extends UmEntity {
	//用户主键
	public userKey?: number;
	//角色主键
	public roleKey?: number;

	//组列表(不与userList同时存在)
	public roleList?: Array<UserRoleEntity>;

	//用户列表(不与roleList同时存在)
	public userListList?: Array<UserRoleEntity>;

}


/**
 * 用户角色对照(Query)
 * @author syj
 * @date 2021-09-22 15:00
 */
export class UserRoleQuery extends UmQuery {

	//用户主键
	public userKey?: number;
}

/**
 * 业务用户(Entity)
 * @author syj
 * @date 2021-09-18 15:32
 */
export class UserTeamEntity extends UmEntity {
	//名称
	public name?: string;
}

/**
 * 用户角色对照(Query)
 * @author syj
 * @date 2021-09-22 15:00
 */
export class UserTeamQuery extends UmQuery {

	//用户主键
	public name?: string;
}

